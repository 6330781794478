.hide_show {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: all @transLin;
    will-change: visibility, opacity;

    &-show {
        visibility: visible !important;
        opacity: 1 !important;
    }
}
