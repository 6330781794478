@import "elements/colors.less";

.arrows {
    &__back {
        margin: 0 8px;
        text-indent: 9px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: -0.4px;

        &_blue {
            display: inline-block;
            height: 16px;
            margin: 25px 0 12px;
            padding-left: 8px;
            line-height: 16px;
            white-space: nowrap;

            background: url("../components/little-components/imgs/arrow_back_blue.svg") left no-repeat;
            background-size: contain;

            &-edit {
                margin: 0 0 12px;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

.city_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 21px;

    &__wrapper {
        width: 100%;
        padding: 25px 30px;
        background: @white;
        border-bottom: 1px solid @greyBorder;
    }

    &__name {
        font-weight: 600;
        font-size: 23px;
        line-height: 24px;
        letter-spacing: -0.7705px;
        color: @colorBlack3A;
    }

    &__close {
        position: absolute;
        right: 15px;
        top: 25px;
        color: #9b9b9b;
        font-size: 13px;
        line-height: 16px;
        font-weight: 300;
        letter-spacing: -0.47px;
        cursor: pointer;

        transition: opacity @transLin;

        &:hover {
            opacity: 0.7;
        }
    }

    &__btn_in_city {
        color: @blue;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.4px;
        cursor: pointer;
    }
}
