@import "vars.less";
@import "utils/measurement-cell.less";

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
.loader-spinner {
    position: absolute;
    margin-top: 5px;
    height: 70px;
    width: 70px;
    background: url(imgs/spinner.svg) 100% 100% no-repeat;
    background-position: center;

    animation: loader-spin 2s infinite linear;

    &-chart {
        display: block;
        margin-left: -35px;
        margin-top: 65px;
        left: 50%;
    }

    &-m {
        margin-top: 0;
        height: 50px;
        width: 50px;
    }
}

/*--------------------------------------------------------------------------------------------------------------*/
body.splash-open {
    overflow: hidden;
    //padding-right: 15px;
}

.stnd_popup {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;

    max-width: 550px;
    min-width: 320px;
    padding: 67px 46px 30px;

    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    &-ask {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 528px;
        height: 310px;
    }

    &-threeQuestions {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 550px;
        min-height: 310px;
        padding: 86px 46px 36px;
    }

    &__splash {
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        z-index: @zindexPopups;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
    }

    &__close {
        position: absolute;
        right: 16px;
        top: 18px;
        cursor: pointer;

        height: 32px;
        width: 32px;
        z-index: 1;

        background-image: url(imgs/cross1.svg);
        &:hover {
            background-image: url(imgs/cross2.svg);
        }
    }

    &__title {
        width: 100%;
        margin-bottom: 22px;
        text-align: center;
        font-weight: 600;
        font-size: 23px;
        line-height: 26px;
        letter-spacing: -0.45px;
        color: @colorBlack26;
    }

    &__description {
        font-size: 15px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.4px;
        margin-bottom: 70px;
        color: @textBlack;
    }

    &__btn_block {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 208px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.35px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s linear;

        &-cancel {
            color: #979797;
            background: #e9e9e9;

            &:hover {
                background: #f7f7f7;
            }
        }

        &-save {
            color: #ffffff;
            background: #6097f3;

            &:hover {
                background: #8ab5fd;
            }
        }

        &-disabled {
            color: #ffffff;
            background: #6097f3;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__h_line {
        height: 8px;
        background: @greyLight;
        margin: 0 -@blankPadding;
    }

    &__three_btn_block {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }

    &__three_btn {
        text-align: center;
        color: @blue;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.35px;
        cursor: pointer;

        &-accept {
            margin-bottom: 22px;
        }

        &-cancel {
            margin-bottom: 32px;
        }

        &-extraAction {
            color: @red;
        }
    }

    &__btn_wrapper {
        width: 119px;

        &-right {
            width: 231px;
        }
    }
}

.start_loading__circle {
    animation: 8s loader ease infinite alternate, 2s loader2 linear infinite;
    stroke: url(#start_loading__gradient);
    stroke-linecap: round;
    stroke-width: 4;
    stroke-dasharray: 94 94;
    stroke-dashoffset: 0;
}

/*-----------------------------------------------------------------------------------------------------------------*/

.loading {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: auto;
    left: 0;
    right: 0;
    width: 300px;
    height: 270px;
    text-align: center;

    font-size: 16px;
    color: @greyText;

    &__img {
        height: 50px;
        width: 300px;
        margin: 20px auto;
        background: url(imgs/logo_ca.svg) center no-repeat;
    }
}

.loading-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 9;
}

/*-------------------------------------------------------------------------------------------------------------*/
.select_measures_radio {
    flex: 1 1 80px;

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 100%;
    vertical-align: top;

    cursor: pointer;

    .mmtCellConstraintsNormal();

    &--multiline {
        .mmtCellConstraintsWide();
    }

    &_item {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;

        text-align: center;
        letter-spacing: -0.3px;

        color: @grey5;
        padding: 10px 0px;
        min-width: 50px;

        &-select {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            background: #6097f3;

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.209069);
            border-radius: 6px;
        }
    }
}

.select_measures {
    cursor: pointer;

    .mmtCellConstraintsNormal();

    &--multiline {
        .mmtCellConstraintsWide();
    }

    &-m {
        height: 100%;
        width: 100%;
        min-width: auto;
        display: inline-flex;
        flex-direction: column;
    }

    &-select {
        background: @blue;
        border-radius: 5px;
    }

    &__input {
        display: inline-block;
        vertical-align: top;
        height: 36px;
        width: 18px;
        flex-shrink: 0;

        background-image: url("apps/cityair/src/libs/common/common-style/imgs/no_check.svg");
        background-position: center;
        background-repeat: no-repeat;
        opacity: 1;
        transition: background-image @transLin;

        &:hover {
            background-image: url("apps/cityair/src/libs/common/common-style/imgs/no_check-hover.svg");
        }

        &-select {
            background-image: url("apps/cityair/src/libs/common/common-style/imgs/check-blue.svg");
            &:hover {
                background-image: url("/apps/cityair/src/libs/common/common-style/imgs/check-hover.svg");
            }
        }
    }

    &__text {
        display: inline;
        vertical-align: middle;
        padding-left: 6px;
        font-size: 13px;
        line-height: 1;
        font-weight: bold;
        color: @greyText;

        &:hover {
            opacity: 0.7;
        }

        &-m {
            padding: 0;
            height: 25px;
            line-height: 30px;
            color: @greyDark;
            overflow: visible;
            text-overflow: ellipsis;
            text-align: center;
            font-weight: 500;

            &-select {
                color: white;
            }
        }

        &-select {
            color: @textBlack;
        }
    }

    &__type {
        color: @greyText;
        font-size: @fontSizeVerySmall;
        font-weight: normal;
        line-height: 20px;
        height: 26px;
        text-align: center;

        &-select {
            color: white;
        }
    }
}

/*-----------------------------------------------------------------------------------------------------------------*/
.online_circle {
    margin-right: 8px;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;

    &__wrapper {
        display: flex;
        align-items: baseline;
    }

    &__text {
        width: 140px;
        color: @grey4;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }

    &-inline {
        position: static;
        display: inline-block;
    }

    &-save_date {
        position: relative;
        top: auto;
        right: auto;
    }

    &-online {
        background: #b2d62a;
    }

    &-battery {
        background: #b2d62a;
        animation-duration: 1s;
        animation-name: signal;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    &-battery_low {
        background: #b2d62a;
        animation-duration: 0.5s;
        animation-name: signal;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    &-offline {
        background: #ff7344;
    }
}

&__description {
    border-bottom: 1px solid @grey;
    pointer-events: none;
}

@keyframes signal {
    from {
        background: #fff;
    }

    to {
        background: #b8e41a;
    }
}

/*-----------------------------------------------------------------------------------------------------------------*/
.loader_map {
    position: absolute;
    z-index: @zindexMainMenu;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);

    &__spinner {
        height: 56px;
        width: 56px;
        position: static;
        margin-top: 0;
    }

    .loader_map__circle {
        animation: 8s loader ease infinite alternate, 2s loader2 linear infinite;
        stroke: url(#Gradient);
        stroke-linecap: round;
        stroke-width: 4;
        stroke-dasharray: 94.0955352783 94.0955352783;
        stroke-dashoffset: 0;
    }
    @keyframes loader {
        0% {
            stroke-dasharray: 84.0955352783 104.0955352783;
        }
        100% {
            stroke-dasharray: 178.19107055664062 10;
        }
    }
    @keyframes loader2 {
        0% {
            stroke-dashoffset: 0;
        }
        100% {
            stroke-dashoffset: -188.19107055664062;
        }
    }
}

/*-----------------------------------------------------------------------------------------------------------------*/

.btn_save {
    position: absolute;
    padding: 8px 0;
    width: 100%;
    transition: @transLin;

    &-wrapper {
        position: fixed;
        bottom: 0;
        width: 600px;
        background: #fff;
        height: 65px;
    }

    &-position {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        padding: 0 20px;
        width: 100%;
    }

    &-cancel {
        color: #ffffff;
        background-color: @grey;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-weight: 700;
        border-radius: 4px;
        cursor: pointer;
    }

    &-save {
        color: #ffffff;
        background-color: @blue;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-weight: 700;
        border-radius: 4px;
        cursor: pointer;
    }

    &-disabled {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }

    &-close {
        bottom: -64px !important;
        transition: @transLin;
    }
    &-open {
        bottom: 0;
        transition: @transLin;
    }
}

/*-----------------------------------------------------------------------------------------------------------------*/

.info-device {
    &-padding {
        padding: 0 20px 1px 20px;
    }
}
