@import "./vars.less";
@import "./utils/ellipsis.less";
@import "./utils/show-directive.less";

@font-face {
    font-family: inter;
    font-weight: 800;
    src: url(../../../../../assets/font/inter/woff2/Inter-ExtraBold.woff2) format("woff2"),
        url(../../../../../assets/font/inter/ttf/Inter-ExtraBold.ttf) format("truetype");
}

@font-face {
    font-family: inter;
    font-weight: 700;
    src: url(../../../../../assets/font/inter/woff2/Inter-Bold.woff2) format("woff2"),
        url(../../../../../assets/font/inter/ttf/Inter-Bold.ttf) format("truetype");
}

@font-face {
    font-family: inter;
    font-weight: 600;
    src: url(../../../../../assets/font/inter/woff2/Inter-SemiBold.woff2) format("woff2"),
        url(../../../../../assets/font/inter/ttf/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: inter;
    font-weight: 500;
    src: url(../../../../../assets/font/inter/woff2/Inter-Medium.woff2) format("woff2"),
        url(../../../../../assets/font/inter/ttf/Inter-Medium.ttf) format("truetype");
}

@font-face {
    font-family: inter;
    font-weight: 400;
    src: url(../../../../../assets/font/inter/woff2/Inter-Regular.woff2) format("woff2"),
        url(../../../../../assets/font/inter/ttf/Inter-Regular.ttf) format("truetype");
}

@font-face {
    font-family: inter;
    font-weight: 300;
    src: url(../../../../../assets/font/inter/woff2/Inter-Light-BETA.woff2) format("woff2"),
        url(../../../../../assets/font/inter/ttf/Inter-Light-BETA.ttf) format("truetype");
}

@font-face {
    font-family: formular;
    font-weight: 700;
    src: url(../../../../../assets/font/formular/woff2/formular-bold.woff2) format("woff2"),
         url(../../../../../assets/font/formular/ttf/formular-bold.ttf) format("truetype"),
         url(../../../../../assets/font/formular/eot/formular-bold.eot) format("eot");
}

@font-face {
    font-family: formular;
    font-weight: 500;
    src: url(../../../../../assets/font/formular/woff2/formular-medium.woff2) format("woff2"),
         url(../../../../../assets/font/formular/ttf/formular-medium.ttf) format("truetype"),
         url(../../../../../assets/font/formular/eot/formular-medium.eot) format("eot");
}

@font-face {
    font-family: formular;
    font-weight: 400;
    src: url(../../../../../assets/font/formular/woff2/formular.woff2) format("woff2"),
         url(../../../../../assets/font/formular/ttf/formular.ttf) format("truetype"),
         url(../../../../../assets/font/formular/eot/formular.eot) format("eot");
}

@font-face {
    font-family: formular-mono;
    font-weight: 400;
    src: url(../../../../../assets/font/formular-mono/formular-mono.woff2) format("woff2"),
         url(../../../../../assets/font/formular-mono/formular-mono.ttf) format("truetype"),
         url(../../../../../assets/font/formular-mono/formular-mono.eot) format("eot");
}

body {
    //min-height: 320px;
    margin: 0;
    background-color: white;

    font-family: -apple-system, BlinkMacSystemFont, inter, sans-serif;
    line-height: 22px;
    font-size: 14px;
    letter-spacing: -0.007em;
    color: @textBlack;

    -webkit-overflow-scrolling: touch;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}

sup,
sub {
    font-size: 75%;
    height: auto;
    line-height: inherit;
    position: relative;
    vertical-align: baseline;
}

sup {
    bottom: 1ex;
}
sub {
    top: 0.5ex;
}

.browser_not_support {
    color: #f9807a;
    font-size: 25px;
    text-align: center;
    margin-top: 100px;
}

.no_highlight_touch {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

// TODO: remove when everything is using scrolls.less
.scroll {
    overflow: auto;

    &::-webkit-scrollbar-track {
        background-color: white;
    }

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        right: -10px;
        background-color: @grayBackground;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: @blue;
    }
}

.scroll-gray {
    overflow: auto;

    &::-webkit-scrollbar-track {
        background-color: #f3f3f3;
    }

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        right: -10px;
        background-color: @grayBackground;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #c2c2c2;
    }
}

.highcharts-credits {
    display: none;
}

.page_title {
    margin: 15px 0;
    font-weight: bold;
    font-size: 35px;
    text-transform: uppercase;
}

.stnd_button {
    min-width: 130px;
    padding: 0 15px;

    text-align: center;
    color: white;
    font-size: 17px;
    line-height: 44px;

    border: none;
    border-radius: @borderRadius;
    cursor: pointer;

    transition: background-color @transLin;

    &-gary {
        background-color: @grey;

        &:hover {
            background-color: @greyHover;
        }
    }

    &-blue {
        background-color: @blue;

        &:hover {
            background-color: @blueHover;
        }
    }

    &-margin_side {
        margin: 0px 10px;
    }

    &-right {
        float: right;
    }
    &-big_padding {
        padding: 0 35px;
    }

    &[disabled] {
        background-color: @greyLight;
    }

    &__blue_style {
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-weight: bold;
        border-radius: 4px;
        cursor: pointer;

        &-outline_blue {
            color: @blue;
            background-color: #fff;
            box-shadow: inset 0px 0px 0px 1px @blue;

            &:hover {
                color: @blueHover;
                box-shadow: inset 0px 0px 0px 1px @blueHover;
            }
        }

        &-blue_fill {
            color: #fff;
            background-color: @blue;
            box-shadow: inset 0px 0px 0px 1px @blue;

            &:hover {
                color: @blueHover;
                box-shadow: inset 0px 0px 0px 1px @blueHover;
            }
        }
    }
}

.action_button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    height: 44px;
    min-width: 208px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.35px;
    border-radius: 4px;

    cursor: pointer;
    user-select: none;
    transition: all @transLin;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        height: 55px;
    }

    &__blue {
        color: white;
        background-color: @btnBlue;

        &:hover:not(&-disabled) {
            background-color: @btnBlueHover;
        }
        &:active:not(&-disabled) {
            background-color: @btnBlueActive;
        }
        &-disabled {
            pointer-events: none;
            background-color: @btnBlueDisabled;
        }
    }

    &__gray {
        color: @textBtnGrey;
        background-color: @btnGrey;

        &:hover:not(&-disabled) {
            background-color: @btnGreyHover;
        }
        &:active:not(&-disabled) {
            background-color: @btnGreyActive;
        }
        &-disabled {
            pointer-events: none;
            color: @textBtnGreyDisabled;
            background-color: @btnGreyDisabled;
        }
    }

    &__outline {
        color: @btnBlue;
        border: 1px solid @btnBlue;
        box-sizing: border-box;

        &:hover:not(&-disabled) {
            color: @btnBlueHover;
            border-color: @btnBlueHover;
        }
        &:active:not(&-disabled) {
            color: @btnBlueActive;
            border-color: @btnBlueActive;
        }
        &-disabled {
            pointer-events: none;
            color: @btnBlueDisabled;
            border-color: @btnBlueDisabled;
        }
    }

    &__empty {
        color: @btnBlue;

        &:hover:not(&-disabled) {
            color: @btnBlueHover;
        }
        &:active:not(&-disabled) {
            color: @btnBlueActive;
        }
        &-disabled {
            pointer-events: none;
            color: @btnBlueDisabled;
        }
    }
}

.text-gray {
    color: @greyText;
}

.text-gray-14 {
    color: @greyText;
    font-size: 14px;
}

.text-gray-16 {
    color: @greyText;
    font-size: 16px;
}
.text-bold-16 {
    color: @textBlack;
    font-size: 16px;
    font-weight: bold;
}
.text-bold-18 {
    color: @textBlack;
    font-size: 18px;
    font-weight: bold;
}

.text-bold-20 {
    color: @textBlack;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.5px;
}

.text-medium-16 {
    font-size: 16px;
    font-weight: 500;
}
.text-medium-14 {
    font-size: 14px;
    font-weight: 500;
}

.text-standard {
    font-size: 15px;
}

.text-subtitle {
    font-size: 15px;
    font-weight: bold;
    color: @greyText;
}

.button_link {
    cursor: pointer;
    text-decoration: none;

    &-blue {
        color: @blue;
        cursor: pointer;
        pointer-events: auto;

        &:hover {
            color: @blueHover;
        }
    }

    &-underscore {
        color: #3a3a3a;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            opacity: 0.8;
        }
    }
}

.anim_showing {
    opacity: 0;

    &-show {
        color: @blue;
        transition: opacity linear 0.3s;
        opacity: 1;
    }
}

.input_error {
    background: #ffbaba;
}

.rows {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 10px 0;
    padding: 0 20px;
    width: 100%;
    border-bottom: 1px solid @greyBorder;

    &-top {
        padding-top: 25px;
    }
    &-flex {
        display: flex;
        align-items: center;
    }

    &-col12 {
        width: 100%;
    }
    &-col11 {
        width: 90%;
    }
    &-col10 {
        width: 80%;
    }
    &-col9 {
        position: relative;
        width: 70%;
    }
    &-col8 {
        width: 62%;
    }
    &-col6 {
        width: 50%;
    }
    &-col5 {
        width: 45%;
    }
    &-col4 {
        width: 33%;
    }
    &-col3 {
        width: 25%;
    }
    &-col2 {
        width: 18%;
    }

    &-col1 {
        width: 8%;
    }

    &-height {
        height: 45px;
        line-height: 60px;
    }

    &-absolute {
        position: absolute;
        top: 10px;
    }

    &-no_border {
        border: none;
    }

    &__height_big {
        height: 70px;
        line-height: 70px;
    }
}

.popup_splash_fixed {
    position: fixed;
    z-index: @zindexPopups;
}

.monitoring_objects {
    margin-left: 20px;
    width: calc(100% - 30px);

    &__title {
        padding-bottom: 16px;
        border-bottom: 1px solid @grey3;
    }

    &__date {
        margin-top: 16px;
        margin-bottom: 20px;
        display: flex;
    }

    &__geo_block {
        display: flex;
    }

    &__text {
        font-weight: 400;
        margin-right: 14px;
        color: @grey7;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.35px;
    }

    &__date_block {
        width: 50%;
    }

    &__sub_title {
        font-size: 12px;
        line-height: 14px;
        color: @grey4;
        margin-bottom: 6px;

        &-bot {
            margin-bottom: 2px;
        }
    }
}

.view_info_devices {
    &__main_info {
        margin-bottom: 30px;
    }

    &__description {
        margin-top: 4px;
        font-size: 12px;
        line-height: 14px;
        color: @grey4;
    }

    &__serial_num {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.4px;
        color: @grey8;

        &-mo {
            cursor: pointer;
            border-bottom: 1px dashed #fff;
            width: fit-content;
            max-width: 100%;

            &:hover {
                border-bottom: 1px dashed #000;
            }
        }
    }

    &__source_name {
        margin-top: 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: @grey4;
    }

    &__point_online {
        position: absolute;
        right: 30px;
        top: 0;
    }
}

.air_aqi {
    &__block {
        display: flex;
        padding: 19px 20px 23px 19px;

        &-rigt {
            width: 100%;
            padding-right: 18px;
        }
    }

    &__num {
        padding-right: 15px;
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &-sub_title {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: @greyText;
            letter-spacing: -0.35px;
        }

        &-title {
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            color: @blackText;
            letter-spacing: -0.45px;
        }
    }

    &__note_top {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.4px;
        color: @colorBlack26;
    }

    &__note_middle {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.35px;
    }

    &__note_bottom {
        margin-top: 20px;
    }

    &__info {
        position: absolute;
        right: 16px;
        top: 19px;
    }
}

.air_aqi__num-p {
    margin: 0 auto !important;
    margin-right: 13px !important;
}
@paddingAll: 0 20px;

@headerHeight: 135px;

.sidebar {
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: @zindexTimeline;
}

.sidebar_container {
    position: absolute;
    top: 0;
    height: 100%;
    width: @adminPanelWidthFat;
    background-color: @white;
    overflow: visible;
    box-shadow: @shadowSmall;

    will-change: transform;
    transition: left @transEase;

    left: -@adminPanelWidthFat + @leftMenuWidth;

    &-active {
        left: @leftMenuWidth;
    }

    &-full_width {
        width: 100%;
    }
}

.search_list_wrapper {
    margin: 0 @headerInnerPadding 20px;
}

.menu_points {
    width: 30px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &__point {
        width: 4px;
        height: 4px;
        margin-bottom: 2px;
        border-radius: 50%;
        background: @grey;
    }

    &__submenu {
        position: absolute;
        right: 40px;
        padding: 0 20px;
        border-radius: 4px;
        top: -5px;
        background-color: #fff;
        transition: all 0.2s linear;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
        z-index: 9;
    }

    &__submenu:after {
        content: "";
        width: 10px;
        height: 10px;
        background: #fff;
        position: absolute;
        right: -5px;
        margin-top: -5px;
        top: 20px;
        transform: rotate(45deg);
    }

    &__submenu_text {
        padding: 10px 0;
        text-align: end;
        cursor: pointer;
        border-bottom: 1px solid @greyBorderLight;

        &:last-child {
            border-bottom: none;
        }
    }
}

input {
    outline: none;
}

.text_indent {
    text-indent: 5px;
}

.padding_top {
    padding-top: 10px;
}

.border_bottom {
    border-bottom: 1px solid @greyBorder;
}

.border_gray {
    border: 1px solid @greyBorder;
}

.flex-between {
    justify-content: space-between;
}

.text_right {
    text-align: right;
}

.btn_open {
    position: fixed;
    z-index: 4;
}

.simple_title {
    margin-right: 10px;

    &-left_margin {
        margin-left: 10px;
    }
}

.admin_panel {
    &__back_wrapper {
        margin-left: 26px;
    }

    &__widget {
        position: fixed;
        left: 125px;
        top: 65px;
        padding: 16px 22px 19px;
        background: #fff;
        width: 245px;
        height: 140px;
        border-radius: 12px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
    }

    &__fixed_block {
        position: fixed;
        width: @adminPanelWidthFat;
        border-bottom: 1px solid @greyBorder;
        background: @white;
        z-index: 3;

        &-padding_bottom {
            padding-bottom: 10px;
        }
    }

    &__relative_block {
        @shiftTop: 48px;
        @shiftTopSlim: 8px;

        &-listPosts {
            margin-top: @headerHeight + @shiftTopSlim;
            height: calc(100% - @headerHeight - @shiftTopSlim);
        }

        &-cityCard {
            height: calc(100% - @headerHeight - @shiftTopSlim + 35px);
        }

        &-listOM {
            overflow-y: scroll;
            margin-top: @headerHeight + @shiftTop + 100;
            height: calc(100% - @headerHeight - @shiftTop - 100px);
            padding-bottom: 30px;
        }

        &-editOM,
        &-viewInfoDevices {
            background: #fff;
            height: 100%;
            padding-bottom: 90px;
        }

        &-listDevices {
            overflow-y: scroll;
            margin-top: @headerHeight + @shiftTop + 50;
            height: calc(100% - @headerHeight - @shiftTop - 50px);
            padding-bottom: 30px;
        }

        &-listUsers {
            overflow-y: scroll;
            margin-top: @headerHeight + @shiftTopSlim;
            height: calc(100% - @headerHeight - @shiftTopSlim);
        }
    }

    &__bottom_block {
        position: fixed;
        width: @adminPanelWidthFat;
        background: #fff;
        bottom: 0px;
        height: 62px;
    }

    &__sub_title {
        margin: 20px 20px 5px 20px;
        padding-bottom: 20px;
        color: @grey;
        font-weight: bold;
    }

    &__title {
        .textSemiBold23();
        color: @grey8;
        display: flex;
        align-items: center;
        margin: 26px @headerInnerPadding @headerTitlePadding;

        &-mo {
            margin: 6px @headerInnerPadding;
        }
    }
}

.page-content {
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__wrapper {
        position: relative;
        top: calc(0px + var(--mobile-app-padding-top));
        right: 0;
        width: 100%;
        height: 100%;

        .admin_panel__fixed_block {
            position: absolute;
            width: 100%;
        }
    }
}

.monitoring_objects_list {
    &__button_add_wrapper {
        position: absolute;
        top: 183px;
        display: flex;
        align-items: center;
        margin-left: 30px;
        width: calc(100% - 30px);
        height: 54px;
        border-bottom: 1px solid @grey3;
        z-index: @zindexTimeline;
    }

    &__sort_list_wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        top: 233px;
        height: 50px;
        margin-left: 30px;
        padding-right: 73px;
        width: calc(100% - 30px);
        border-bottom: 1px solid @grey3;
        z-index: @zindexTimeline;
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: calc(100% + 45px);
    height: calc(100% + 45px);
    margin-left: -45px;
    margin-top: -45px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 5;
}

.popup {
    &__all_change {
        border-bottom: 1px solid @grey;
    }
}

.add_btn {
    position: absolute !important;
    right: 20px;
    bottom: 70px;
    height: 50px;
    width: 50px;
    background: url(./img/add.svg) 50% 50% no-repeat;
    cursor: pointer;
    z-index: 3;
}

.sub_title {
    padding: 20px;
}

.mat-radio-button {
    width: 100px;
    text-align: center;
}

.mat-radio-container,
.mat-radio-outer-circle,
.mat-radio-inner-circle {
    width: 16px !important;
    height: 16px !important;
}

.admin_panel__notifications {
    position: fixed;
    bottom: 100px;
    left: 0;
    z-index: 2;
}

.admin_panel__loader {
    position: absolute;
    bottom: 60px;
    left: 9px;
    height: 40px !important;
    width: 40px !important;
}

.admin_panel__error {
    position: absolute;
    width: 400px;
    transform: translate(-400px);

    padding: 8px 15px;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 0 10px 10px 0;

    &-show {
        transform: translate(0px);
    }
}

.client-icon {
    width: 100%;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 38px auto;
}

.sidebar__feedback-button {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: @zindexTimeline + 1;
}

.sidebar__feedback-form {
    position: absolute;
    bottom: 20px;
    left: 78px;
    right: 0;
}

@import "listOM.less";
@import "viewInfoOM.less";
@import "listDevices.less";
@import "viewInfoDevices.less";
@import "cityCard.less";
