.list_devices {
    margin: 28px;
    margin-bottom: 14px;

    &__card_popup {
        position: absolute;
        top: 0;
        left: 586px;
        z-index: 30;
        padding: 11px 8px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 1px 36px rgba(0, 0, 0, 0.1);
    }

    &__sort_list_wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        top: 184px;
        height: 50px;
        margin-left: 30px;
        padding-right: 65px;
        width: calc(100% - 30px);
        border-bottom: 1px solid #e6ecf2;
        z-index: @zindexTimeline;
    }

    &__empty {
        margin: auto;
        margin-top: 60px;

        text-align: center;
        font-weight: 600;
        font-size: 16px;
        color: @grey7;
    }

    &__point_online {
        position: absolute;
        right: 30px;
        top: 15px;
        width: 120px;
    }

    &__text {
        color: @grey4;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        &-last_time {
            margin-top: 8px;
        }
    }

    &__block {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        width: calc(100% - 30px);
        justify-content: center;
        background: #fff;
        height: 83px;
        border-left: 4px solid transparent;
        border-bottom: 1px solid @grey3;

        &-select {
            border-left: 4px solid @blue;
        }
    }

    &__sub_title {
        margin-right: 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: @grey6;
    }

    &__info_device_wrapper {
        overflow: hidden;
        height: 0;
        margin-right: 20px;
        transition: all 0.2s linear;

        &-open {
            height: 133px;
        }
    }

    &__description {
        padding-top: 3px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: @grey4;
    }

    &__arr {
        position: absolute;
        top: 12px;
        right: 30px;
        width: 10px;
        height: 10px;
        background: url("img/arrow_gray.svg") no-repeat;
        background-size: 100%;
        transition: all 0.2s linear;
        transform: rotate(180deg);

        &-open {
            transform: rotate(0);
        }
    }

    &__block_title_left {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: -0.35px;
        color: #979797;
        margin-right: 5px;
    }

    &__block_wrapper {
        border-bottom: 1px solid @grey3;
        margin-left: 20px;
        &:last-child {
            border-bottom: none;
        }
    }

    &__main_block {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        cursor: pointer;
    }

    &__block_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        height: 23px;
        line-height: 25px;
        border-bottom: 1px dotted #000;
        cursor: pointer;

        &-sn {
            width: fit-content;
            cursor: pointer;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.4px;
            color: @textBlack;
            border-bottom: 1px dashed #fff;

            &:hover {
                border-bottom: 1px dashed @textBlack;
            }
        }

        &-mo {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            margin-top: 4px;
            font-size: 12px;
            line-height: 14px;
            color: @grey6;
        }

        &-mo_block {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 4px;
            float: left;
            font-size: 12px;
            line-height: 14px;
            color: @grey7;

            &:not(:last-child):after {
                content: "|";
                display: inline-block;
                padding: 0 5px;
            }
        }

        &-mo_name {
            border-bottom: 1px dashed #fff;
            cursor: pointer;

            &:hover {
                border-bottom: 1px dashed #000;
            }
        }

        &-not_conect {
            line-height: 1;
        }

        &__search {
            width: 100%;
            height: 25px;
            line-height: 25px;
            border: none;
            border-bottom: 1px solid @greyBorder;
            background: @greyLight;
            outline: none;

            &::placeholder {
                color: #a2a2a2;

                line-height: 22px;
                font-size: 15px;
            }
        }
    }
}
