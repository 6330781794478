.input {
    margin: 15px 32px 0 32px;
    position: relative;

    &__textarea {
        resize: none;
        margin-top: 20px;
        outline: none;

        &:active {
            outline: none;
        }
    }

    &-email {
        position: relative;
        padding: 15px 5px 0 5px;
    }

    &-mo {
        margin: 15px 32px 0 35px;
    }

    &__coordinate {
        width: 215px !important;
    }

    &__title_field {
        position: absolute;
        top: -19px;
        left: 0px;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height, or 117% */

        letter-spacing: -0.2px;

        /* Grey / 4 */

        color: #bfbfbf;

        &-top_absolut {
            top: 0;
        }

        &-top {
            position: relative;
            padding: 0 0 0 5px;
            font-size: 14px;
        }
    }

    &__entry_field {
        background: @yellow;
        width: 100%;
        border: 1px solid @greyBorder;

        &-very_small {
            height: 20px;
            padding: 0 5px 0 5px;
        }

        &-small {
            height: 40px;
            padding: 15px 0 0 5px;
        }

        &-big {
            height: 45px;
            padding: 0 0 0 5px;
            border-top: none;
            outline: none;
            resize: none;
        }
    }

    &__disabled {
        height: 40px;
        line-height: 40px;

        &-gray {
            background: @greyLight;
        }

        &-textarea {
            border-right: none;
            border-left: none;
            border-top: none;
            border-bottom: 1px solid @greyLight;
            outline: none;
            resize: none;
            padding: 0;
            margin: 0 0 -10px 0;
            height: 75px;
            line-height: 15px;
            width: 100%;
            background: rgb(255, 255, 255);
        }
    }

    &-background_yello {
        background: @yellow;
    }
}

.mat_select {
    position: absolute;
    top: -10px;
    width: 100%;

    &-text > * {
        font-size: 20px;
        font-weight: 700;
    }

    &__select {
        width: 100%;

        &-yellow {
            background: @yellow;
            border: 1px solid @greyBorder;
        }

        &-none_line > .mat-form-field-wrapper {
            padding-bottom: 5px !important;
        }

        &-none_line > .mat-form-field-wrapper > .mat-form-field-underline {
            display: none;
        }

        &-inner_title > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix {
            padding: 5px 0 0 0 !important;
        }
        &-select_device > .mat-form-field-wrapper {
            padding: 5px 0 8px 0 !important;
        }

        &-select_device > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix {
            padding: 5px 0 0 0 !important;
            border-top: 0;
        }

        &-simple {
            display: inline-block;
            position: relative;
            padding-top: 7px;
            height: 40px;
        }

        &-simple > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix {
            padding: 5px 0 0 0;
            margin: 0;
            border: 0;
        }
    }

    &__title {
        position: absolute;
    }

    &__description {
        position: absolute;
        top: 42px;
    }
}

.select_interval {
    width: 140px;
    height: 40px;
    text-indent: 10px;
    background: @yellow;
    font-size: 15px;
}

.station_add {
    background: #fff;
    padding-bottom: 10px !important;
    margin-bottom: 8px;

    &__info {
        margin-top: 20px;
    }
}

.mat-select-value {
    padding-left: 5px !important;
}

.mat-select-arrow-wrapper {
    padding-right: 10px !important;
}
