@import "../../notifications.vars.less";

@headerHeight: 191px;

.notifications_subscriptions {
    height: 100%;

    &__filter-tabs {
        padding: 25px 28px 10px;
        display: flex;
        justify-content: center;

        width: 100%;
        background: white;
        box-shadow: @shadowOver;

        position: relative;
        z-index: 1;

        &--initial-position {
            box-shadow: none;
        }
    }

    &__main {
        top: 0;
        width: 100%;
        background: @white;
        box-shadow: @shadowSmall;
        position: relative;
        z-index: 3;

        &_wrapper {
            display: flex;
            padding: 40px 30px 24px;

            .settings_subtitle__icon {
                align-self: flex-end;
                margin-left: 13px;
            }
        }
    }

    &__tabs-wrapper {
        padding: 0 30px;
    }

    &__title {
        color: @grey8;
        font-weight: 600;
        font-size: 23px;
        line-height: 26px;
        letter-spacing: -0.45px;
    }

    &__arrows_back {
        margin: 22px 32px 18px;
    }

    &__list {
        position: absolute;
        top: @headerHeight;
        height: calc(100% - @headerHeight);
        width: 100%;
        background: @white;
    }

    & .notifications_container {
        background: @white;
        height: calc(100% - @headerHeight);
        overflow: auto;
    }
}
