.textDefaultStyles() {
    font-family: Inter;
    font-style: normal;
}

// Light
.textLight() {
    .textDefaultStyles();
    font-weight: 300;
}

.textLight12() {
    .textLight();
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.3px;
}

// Regular
.textRegular() {
    .textDefaultStyles();
    font-weight: normal;
}

.textRegular10() {
    .textRegular();
    font-size: 10px;
    line-height: 12px;
    letter-spacing: normal;
}

.textRegular12() {
    .textRegular();
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.3px;
}

.textRegular13() {
    .textRegular();
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.4px;
}

.textRegular14() {
    .textRegular();
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.35px;
}

.textRegular15() {
    .textRegular();
    font-size: 15px;
    line-height: 17px;
    letter-spacing: -0.4px;
}

.textRegular16() {
    .textRegular();
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.4px;
}

// Medium
.textMedium() {
    .textDefaultStyles();
    font-weight: 500;
}
.textMedium10() {
    .textMedium();
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.5px;
}
.textMedium11() {
    .textMedium();
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.5px;
}
.textMedium12() {
    .textMedium();
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.3px;
}

.textMedium14() {
    .textMedium();
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.35px;
}

.textMedium15() {
    .textMedium();
    font-size: 15px;
    line-height: 17px;
    letter-spacing: -0.4px;
}

.textMedium16() {
    .textMedium();
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.4px;
}

.textMedium18() {
    .textMedium();
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.6px;
}

.textMedium20() {
    .textMedium();
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -1px;
}

// Semi Bold
.textSemiBold() {
    .textDefaultStyles();
    font-weight: 600;
}
.textSemiBold10() {
    .textSemiBold();
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.38px;
}
.textSemiBold12() {
    .textSemiBold();
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.3px;
}

.textSemiBold14() {
    .textSemiBold();
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.35px;
}

.textSemiBold15() {
    .textSemiBold();
    font-size: 15px;
    line-height: 17px;
    letter-spacing: -0.4px;
}

.textSemiBold16() {
    .textSemiBold();
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.4px;
}

.textSemiBold18() {
    .textSemiBold();
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.6px;
}
.textSemiBold20() {
    .textSemiBold();
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.7px;
}
.textSemiBold21() {
    .textSemiBold();
    font-size: 21px;
    line-height: 23px;
    letter-spacing: -0.8px;
}
.textSemiBold23() {
    .textSemiBold();
    font-size: 23px;
    line-height: 26px;
    letter-spacing: -1px;
}
.textSemiBold28() {
    .textSemiBold();
    font-size: 28px;
    line-height: 26px;
    letter-spacing: -1.5px;
}
.textSemiBold32() {
    .textSemiBold();
    font-size: 32px;
    line-height: 26px;
    letter-spacing: -2px;
}
// Bold
.textBold() {
    .textDefaultStyles();
    font-weight: 700;
}
.textBold16() {
    .textBold();
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.6px;
}
.textBold20() {
    .textBold();
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.6px;
}
.textBold23() {
    .textBold();
    font-size: 23px;
    line-height: 27.84px;
    letter-spacing: -0.4px;
}
.textBold28() {
    .textBold();
    font-size: 28px;
    line-height: 26px;
    letter-spacing: -1.5px;
}
.textBold32() {
    .textBold();
    font-size: 32px;
    line-height: 24px;
    letter-spacing: -1.5px;
}
.textBold36() {
    .textBold();
    font-size: 36px;
    line-height: 24px;
    letter-spacing: -1.5px;
}
