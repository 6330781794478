@import "main";

.list_OM {
    &__role {
        &-name {
            padding: 8px 0 0 20px;
        }
    }

    &__search_img {
        position: absolute;
        padding-top: 3px;
        color: @greyBorder;
    }

    &__rows {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 0px;
    }

    &__search {
        width: 100%;
        border: none;
        outline: none;
        background: #ffffff;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.176489);
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 48px;
        letter-spacing: -0.4px;
        padding: 0 15px;

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: -0.4px;
            color: rgba(186, 186, 186, 0.687227);
        }
    }

    &__station {
        margin-top: 10px;
    }

    &__block {
        position: relative;
        background: #fff;
        margin-top: 6px;
        margin-left: 25px;
        padding: 12px 0;
        width: calc(100% - 25px);
        border-left: 4px solid transparent;
        border-bottom: 1px solid #e1e1e1;
        cursor: pointer;
    }

    &__name_station {
        max-width: 300px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.1px;
        border-bottom: 1px dashed #fff;
        width: fit-content;
        color: @grey7;
        cursor: pointer;

        &:hover {
            border-bottom: 1px dashed #000;
        }
    }

    &__online_circle {
        position: absolute;
        top: 18px;
        right: 20px;
        height: 9px;
        width: 9px;
        border-radius: 50%;

        &-online {
            background: #b8e41a;
        }

        &-offline {
            background: red;
        }
    }
    // Дубль код нухжно на рефактор
    &__block_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        height: 23px;
        line-height: 25px;
        border-bottom: 1px dotted #000;
        cursor: pointer;

        &-mo_block {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 3px;
            float: left;
        }

        &-mo_name {
            line-height: 1;
        }
    }
}

