.title_pole {
    position: absolute;
    top: 3px;
    line-height: normal;

    &-margin {
        margin: 0 0 0 5px;
    }
}

.margin_bottom {
    margin-bottom: -7px;
}

.users_list {
    margin-bottom: 15px;

    &__subtitle {
        margin: 10px 0;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 15px;
        line-height: 17px;
        letter-spacing: -0.4px;
        color: #262626;
        background: #f7f7f7;
    }

    &__block {
        padding: 20px;
        padding-top: 0;
        width: 100%;
    }

    &__role {
        color: #989898;
        font-size: 14px;
    }

    &__list {
    }

    &__user {
        display: inline-block;
        margin: 8px 6px 0 0;
        border-radius: 4px;
        padding: 8px 10px;
        background: #f7f7f7;

        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.35px;
        color: #4a4a4a;
    }
}

.attached_stations {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid @greyBorder;
    cursor: pointer;

    &__wrapper {
        width: 100%;

        overflow-x: auto;
        overflow-y: hidden;
    }

    &-block {
        padding: 0 10px 0 10px;
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    &-active {
        border-bottom: 2px solid #00b0f0;
    }
}

.info_status {
    &__online_text {
        text-align: right;
        padding-right: 15px;

        font-size: 12px;
        color: #bababa;
    }
}

.create_om {
    height: calc(100% - 150px);

    &__subtitle {
        margin-top: 25px;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.2px;
        color: #bfbfbf;
    }

    &__btns {
        position: absolute;
        bottom: 10px;
        width: 100%;
        padding: 0 32px;
    }
}
