@import "../../notifications.vars.less";

.noselect {
    user-select: none;
}

.notifications_panel {
    height: 100%;
}

.notifications_tips {
    display: flex;
    padding: 17px @padding30 @padding20;

    &__img {
        margin-right: 20px;
        flex-shrink: 0;
        flex-basis: 29px;
        height: 35px;
        background-size: contain;
        background-repeat: no-repeat;

        &-measurements {
            background-image: url("../../img/measurement.svg");
        }

        &-service {
            background-image: url("../../img/service-icon.svg");
        }
    }

    &__text {
        &-padding_button {
            padding-bottom: 16px;
        }

        &-bold {
            font-weight: 600;
            margin-bottom: 4px;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.4px;
            color: @grey7;
        }

        &-small {
            margin-top: 10px;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: -0.2px;
            color: @grey4;
        }

        &-service {
            width: 360px;
        }
    }

    &__not-available {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        color: @grey7;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.4px;
    }
}

.card_popup {
    position: absolute;
    top: 0;
    left: @adminPanelWidthFat + @mapPageLRpadding;
    z-index: @zindexPopups;
    padding: 11px 8px;
    border-radius: 8px;
    background: #fff;
    box-shadow: @shadowBig;

    &__separator {
        border-top: 1px solid @grey3;
        margin: 10px 14px;
    }

    &__item {
        display: flex;
        align-items: center;
        width: 227px;
        height: 35px;
        margin-top: 6px;
        border-radius: 4px;
        padding-left: 13px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: @grey7;
        cursor: pointer;

        &:first-child {
            margin-top: 0;
        }

        &:hover {
            background: @grey2;
        }

        &--danger {
            color: @red;
        }
    }

    .stnd_popup {
        position: relative;
        background: none;
        min-width: auto;
        max-width: auto;
        box-shadow: none;
        padding: 0;

        &__close {
            display: none;
        }

        &__splash {
            position: relative;
            background: none;
        }
    }
}

.subscription_card_content {
    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: @grey4;
        margin-bottom: 7px;

        &-no_margin {
            margin-bottom: 0px;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 21px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.35px;
        color: @grey7;

        &-service_notification {
            display: block;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item {
        margin-right: 30px;

        &-service_notification {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        padding: 0 16px 10px;

        .action_button {
            width: 217px;
        }
    }
}

/********************setting************/

.settings_block {
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background: #fff;

    .alert_error {
        position: absolute;
        color: @red;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.2px;
        margin-top: 8px;
        height: 0;
        transition: all 0.2s linear;

        &-active {
            height: 14px;
        }

        &-relative {
            position: relative;
        }
    }

    .input_surrogate {
        cursor: pointer;
    }

    .input_surrogate.alert_error,
    input.alert-error {
        border: 1px solid @red;
    }
}

.settings_input_forms {
    display: block;
    position: absolute;
    width: 100%;
    overflow: auto;
    height: calc(100% - 74px);
    padding-bottom: 10px;
}

.settings_header {
    position: relative;
    margin: 28px 30px 0px;

    &-wrapper {
        margin-bottom: 0px;
    }
}

.settings {
    &__section {
        margin-bottom: 40px;
    }

    &__input-wrapper {
        position: relative;
        margin: @padding30 @padding30 0;

        &--text {
            margin-top: @padding20;
        }

        .tags-list {
            margin-top: -7px;
        }
    }

    &__input-label {
        margin-bottom: @padding10;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.2px;
        color: @grey4;

        &--text {
            margin-bottom: @padding8;
        }

        &-wrapper {
            display: flex;
        }

        &__icon {
            align-self: auto;
            margin-left: 13px;
            margin-top: -2px;
        }
    }
}

.settings_title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 23px;
    line-height: 26px;
    color: @grey8;
}

.input_name {
    display: flex;
    justify-content: space-between;
    font-family: -apple-system, BlinkMacSystemFont, inter, sans-serif;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 16px;
    text-indent: 14px;
    letter-spacing: -0.4px;
    border: 1px solid @grey3;
    border-radius: 4px;
    box-sizing: border-box;
    background: @white;
    transition: all 0.2s linear;
    box-shadow: @shadowPlaceholder;

    &__arrow {
        margin-right: 16px;
        width: 14px;
        height: 8px;
        background: url("../../img/arrow_grey_notifications.svg") no-repeat;
        background-size: contain;
        background-position: center;
        transition: all @transLin;

        &-active {
            transform: rotate(180deg);
        }
    }

    &::placeholder {
        color: @grey4;
        font-family: inter;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.4px;
    }

    &:hover {
        box-shadow: @shadowSmall;
    }

    &:focus,
    &:focus-within {
        background-color: @white !important;
        border: 1px solid @grey4;
        box-shadow: @shadowSmall;
    }
}

.settings_subtitle {
    display: flex;
    align-items: center;
    height: 30px;
    width: 100%;
    padding: 0 30px;
    background: @grey2;

    &__name {
        margin-right: 10px;
        font-weight: 600;
        font-size: 15px;
        line-height: 17px;
        letter-spacing: -0.4px;
        color: @grey8;
    }

    &__icon {
        height: 18px;
        width: 18px;
        background: url("../../img/info.svg") no-repeat;
        background-size: contain;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
            opacity: 0.7;
        }
    }
}

.setting_post_list {
    &__container {
        display: flex;
        flex-wrap: wrap;
        margin: 17px 30px 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.35px;
        color: @grey7;

        &-service_notification {
            display: block;
        }
    }
}

.settings_description {
    margin: 20px 30px 0;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.2px;
    color: @grey4;

    &-save {
        margin: 20px 0 0;
    }
}

.list_substances {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &__item {
        margin: 1px;
        padding: 8px 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.35px;
        border-radius: 6px;
        transition: all 0.2s linear;
        cursor: pointer;

        sup {
            vertical-align: top;
            position: relative;
            top: -25%;
        }

        sub {
            vertical-align: bottom;
            position: relative;
            top: 25%;
        }

        &:hover {
            background: @grey2;
        }

        &-active {
            color: @blue;
            background: @btnBlueDisabled;

            &:hover {
                background: @colorBlueLightHover;
            }
        }
    }
}

.settings_radio {
    & input[type="radio"]:checked ~ div {
        background: url("../../img/radio_checked--inline.svg") no-repeat;
        background-size: contain;
    }

    & input[type="radio"]:checked:hover ~ div {
        background: url("../../img/radio_checked_hover--inline.svg") no-repeat;
        background-size: contain;
    }

    & input[type="radio"]:hover ~ div {
        background: url("../../img/radio_hover--inline.svg") no-repeat;
        background-size: contain;
    }

    &__str {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: @padding10;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    &__btn {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        cursor: pointer;

        &-input {
            position: absolute;
            opacity: 0;
            z-index: 20;
        }

        &-img {
            position: relative;
            background: url("../../img/radio--inline.svg") no-repeat;
            background-size: contain;
            cursor: pointer;
            transition: all 0.2s linear;
            opacity: 1;
            z-index: 10;
        }
    }

    &__text {
        margin-left: 8px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: @grey7;
    }
}

.setting_input {
    width: 100%;

    &__str {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 14px;
        line-height: 16px;

        &-service {
            justify-content: flex-start;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &__title {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.4px;

        &-service {
            width: 200px;
        }
    }

    &__input_text {
        display: flex;
        align-items: center;
        width: 83%;
        height: 40px;
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.4px;
        border: 1px solid @grey3;
        box-sizing: border-box;
        border-radius: 4px;
        background: @grey2;
        pointer-events: none;
        transition: all 0.2s linear;

        &-service {
            width: 83px;
            margin: 0 10px;
        }
    }
}

.settings_footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 15px 35px;
    background: #fff;
    box-shadow: @whiteShadow;
    z-index: @zindexTimeline;
}
